import React from 'react';
import styled from "@emotion/styled"
import { mq } from "../utils/style";
import FlatButton from './flatbutton';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const fieldNameMap = {
  name: 'GPU名称',
  passmark: 'Passmark',
  vmark: '3DMark',
  fullHd: 'FHD FPS',
  qhd: 'QHD FPS',
  fourK: '4K FPS',
  tdp: 'TDP(W)',
  price: '価格($)',
  cospa: 'コスパ',
  year: '発売年',
};

const fieldArray = [
  {
    id: 'name',
    placeholder: '例:GTX, RTX',
    filterType: 'string',
    css: {width: '130px'}
  },
  {
    id: 'passmark',
    placeholder: '1 - 7000'
  },
  {
    id: 'vmark',
  },
  {
    id: 'fullHd',
  },
  {
    id: 'qhd',
  },
  {
    id: 'fourK',
  },
  {
    id: 'tdp',
  },
  {
    id: 'price',
  },
  {
    id: 'cospa',
  },
  {
    id: 'year',
  },
];

// const CheckBoxes = styled.div({
//   padding: '5px',
//   border: '3px solid #26a69b',
//   borderRadius: '8px',
// });

const CheckBoxes = styled.div({
  paddingRight: '5px',
  input: {
    display: 'none',
  },
  'input:checked + label': {
    backgroundColor: '#159b02',
    color: 'white',
    '&.radeon': {
      backgroundColor: '#ea2c13',
    },
  },
  label: {
    display: 'inline-block',
    margin: '2px',
    padding: '5px',
    borderRadius: '3px',
    backgroundColor: '#f3f6fa',
    border: '2px solid #159b02',
    '&.radeon': {
      border: '2px solid #ea2c13',
    },
  }
});

const ContentWrapper = styled.div({
  [mq('mobile')] : {
    overflow: 'scroll',
    width: '100%',
  },
  contentVisibility: 'auto',
  containIntrinsicSize: '800px',
});

const TableWrapper = styled.table({
  font: '1.3rem Arial,Sans-serif',
  '&.shrink': {
    font: '1rem Arial,Sans-serif',
    width: '100%',
    th: {
      wordBreak: 'break-all',
    },
    td: {
      padding: '3px',
    },
  },
  borderSpacing: '0',
  border: '1px solid #cdcdcd',
  borderWidth: '1px 0 0 1px',
  width: '850px',
  input: {
    width: '100%',
  },
  '.rank': {
    color: '#f5683a',
  },
  '.tablehead': {
    backgroundPosition: 'center center',
    backgroundRepeat: 'repeat-x',
    backgroundImage: 'url(data:image/gif;base64,R0lGODlhAQBkAOYAAN/e39XU1fX19tTU1eXm5uTl5ePk5OLj4+Hi4vX29fT19PP08/Lz8vHy8fDx8O/w7+7v7uzt7Orr6ufo5/T08/Pz8ufn5uLi4eDg39/f3t3d3Nzc29HR0NDQz8/Pzuvq6urp6eno6Ojn5+fm5tfW1tbV1dTT09PS0tLR0dHQ0NDPz/f39/b29vX19fT09PPz8/Ly8vHx8e/v7+7u7u3t7ezs7Ovr6+rq6unp6ejo6Ofn5+bm5uXl5eTk5OPj4+Li4uHh4eDg4N/f397e3t3d3dzc3Nvb29ra2tnZ2djY2NfX19XV1dPT09LS0tHR0dDQ0M/Pz8rKysXFxf///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAFMALAAAAAABAGQAAAdegCsrLC0tLi+ILi6FCSwsCS0KkhQVDA0OMjM0NTYfICEiIzw9P0AYGUQaG0ZHSEoDTU9Qs08pTk1MSyRJR0VDQT8+PTw7Ojg3NTMyMTAvi4WOhC0vMTI1OT9GTlFSgQA7)',
    borderRight: '#cdcdcd 1px solid',
  },
  [mq('mobile')] : {
    'tr td:first-of-type,tr th:first-of-type': {
      position: 'sticky',
      left: 0,
      zIndex: 1,
    }
  },
  td: {
    padding: '6px',
    border: '1px solid #ccc',
    borderWidth: '0 1px 1px 0',
  },
  'td:first-of-type': {
    cursor: 'pointer',
    color: '#005c9c',
    '&:hover': {
      fontWeight: 'bold',
      color: '#ff8000',
      fontSize: '1.5rem',
      textDecoration: 'underline',
    }
  },
  th: {
    border: '1px solid #ccc',
    borderWidth: '0 1px 1px 0',
  },
  '.asc': {
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(data:image/gif;base64,R0lGODlhEAAQANUAAA5NDBBYDpDVjp7inJ/fnSCsGhyYFxFdDhBXDSO8HSK2HB2aGBuPFhqNFhmHFRZ2EhBVDSS8Hh6fGRuTFxd5Eww/Chp7Fhx8GCy/JjnDMyNuHzy9N0LFPVTCTzBkLmbQYnDTbHnWdo/djP///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACMALAAAAAAQABAAAAY4wJFwSCwaj8ikcslMbpojR0bEtEwwoIHywihEOCECUvNoGBaSxEdg9FQAEAQicKAoOtC8fs8fBgEAOw==)',
  },
  '.dsc': {
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(data:image/gif;base64,R0lGODlhEAAQANUAAFWWUzRUMw1EChqKFQxCCiO8HSCqGhyUFxVvERRpECGyHB+mGhiAFAs5CSu+JTHAKyynKCuhJzXCMDbCMD3EOELFPSl7JkvIRjycOFDKS1LKTVPLT1XLUF7OWXXVcYXagmmUZ112XCJEIEdjRf///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACQALAAAAAAQABAAAAY4QJJwSCwaj8ikcskkghKGimbD6Xg+AGOIMChIKJcMBjlqMBSPSUQZEBwcEKYIsWiSLPa8fs9HBgEAOw==)',
  },
  '.inheader': {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  'thead tr:first-of-type': {
    [mq('pc')] : {
      cursor: 'pointer',
    }
  },
  'tbody tr td:first-of-type': {
    fontWeight: '700',
  },
  'tr:nth-of-type(odd)': {
    '&:hover > td': {
      backgroundColor: '#cfcfcf',
    },
    'td': {
      backgroundColor: '#ebfaeb',
    }
  },
  'tr:nth-of-type(even)': {
    '&:hover > td': {
      backgroundColor: '#e9e9e9',
    },
    'td': {
      backgroundColor: '#fff',
    }
  },
  '.bar': {
    textAlign: 'center',
    fontWeight: 700,
  },
  '.b1': {
    backgroundColor: '#f3e869'
  },
  '.b2': {
    backgroundColor: '#b4c2f4'
  },
  '.b3': {
    backgroundColor: '#f4b4c9'
  },
  '.b4': {
    backgroundColor: '#ecb87d'
  },
  '.b0': {
    backgroundColor: '#8ae77c'
  },
});
let maxPassmark;
let maxVmark;
let maxFullHD;
let maxQHD;
let maxFourK;

class TableRow extends React.PureComponent {
  render() {
    const { cpu, fieldArray } = this.props;
    const keyword = cpu.name.replace(/Intel%20|AMD%20/, '');
    const url = `https://www.amazon.co.jp/s/ref=as_li_ss_tl?k=${keyword}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_1&linkCode=ll2&tag=daigakuseitus-22&linkId=35d63770bb2f4224560575dae588479a&language=ja_JP`;
    return (
      <tr>
        {fieldArray.map((field, i) => {
          switch (field.id) {
            case 'name':
              return <td key={field.id}>{cpu.rank && <span className="rank">{cpu.rank}位:</span>}<a href={url} target="_blank" rel="nofollow">{cpu.name}</a></td>
            case 'passmark':
                return <td key={field.id}><div className='b0 bar' style={{width: `${cpu.passmark * 100/maxPassmark}%`}}>{cpu.passmark || ''}</div></td>
            case 'vmark':
              return <td key={field.id}><div className='b1 bar' style={{width: `${cpu.vmark * 100/maxVmark}%`}}>{cpu.vmark || ''}</div></td>
            case 'fullHd':
              return <td key={field.id}><div className='b2 bar' style={{width: `${cpu.fullHd * 100/maxFullHD}%`}}>{cpu.fullHd || ''}</div></td>
            case 'qhd':
              return <td key={field.id}><div className='b3 bar' style={{width: `${cpu.qhd * 100/maxQHD}%`}}>{cpu.qhd || ''}</div></td>
            case 'fourK':
              return <td key={field.id}><div className='b4 bar' style={{width: `${cpu.fourK * 100/maxFourK}%`}}>{cpu.fourK || ''}</div></td>
            default:
              return <td key={field.id}>{cpu[field.id] || ''}</td>
          }
        })}
      </tr>
    )
  }
}

export default class HikakuTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpuSubSet: props.cpuData,
      scale: '縮小表示',
      checkboxes: {},
      filters: {},
      url: props.location.href
    };
    this.sort = {
      fieldName: (props.sort && props.sort.fieldName) || 'passmark',
      order: (props.sort && props.sort.order) || 'dsc',
    };
    this.location = props.location;
    // maxの値を計算する
    maxPassmark = Math.max(...props.cpuData.map(cpu => cpu.passmark));
    maxVmark = Math.max(...props.cpuData.map(cpu => cpu.vmark));
    maxFullHD = Math.max(...props.cpuData.map(cpu => cpu.fullHd));
    maxQHD = Math.max(...props.cpuData.map(cpu => cpu.qhd));
    maxFourK = Math.max(...props.cpuData.map(cpu => cpu.fourK));
    this.fieldArray = props.fieldArray || fieldArray;
  }

  componentDidMount = () => {
    // http://localhost:8000/cpu/?tdp=%3C%3D50&cospa=%3C%3D50
    if (!this.location.search) {
      return;
    }
    const keyValueArray = this.location.search.substring(1).split('&');
    const queryObj = keyValueArray.reduce((acc, cur) => {
      const [key, value] = cur.split('=');
      if (key && value !== undefined) {
        const field = this.fieldArray.find(field => field.id === key);
        if (!field) {
          return acc;
        }
        acc[key] = {
          value: decodeURIComponent(value),
          type: field.filterType || 'number'
        };
      }
      return acc;
    }, {});
    this.createCpuList(this.state.checkboxes, {
      ...this.state.filters,
      ...queryObj,
    });
  }

  createCpuList = (newCheck, newFilters) => {
    let cpuSubSet = [...this.props.cpuData];
    // filter
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key].type === 'string') {
        const regExp = new RegExp(newFilters[key].value.replace(/ /g, '').split(',').filter(e => !!e).join('|'), 'i');
        cpuSubSet = cpuSubSet.filter((cpu) => {
          return regExp.test(cpu[key].replace(/ /g, ''));
        });
      } else {
        let numValue = newFilters[key].value.replace(/ /g, '');
        cpuSubSet = cpuSubSet.filter(cpu => {
          const value = cpu[key];
          if (/^[0-9.]+-[0-9.]+$/.test(numValue)) {
            const range = numValue.split('-');
            return range[0] <= value && value <= range[1];
          } else if (/^[0-9.]+-$/.test(numValue)) {
            const range = numValue.split('-');
            return range[0] <= value;
          } else if (/^-[0-9.]+$/.test(numValue)) {
            const range = numValue.split('-');
            return value <= range[1];
          } else if (/^(>|>=|<|<=)[0-9.]+$/.test(numValue)) {
            const temp = numValue.match(/^(>|>=|<|<=)([0-9.]+)$/);
            const inEqualitySign = temp[1];
            const compareValue = temp[2];
            if (inEqualitySign === '>') {
              return value > compareValue;
            } else if (inEqualitySign === '>=') {
              return value >= compareValue;
            } else if (inEqualitySign === '<') {
              return value < compareValue;
            } else {
              return value <= compareValue;
            }
          }
          return true;
        });
      }
    });
    // sort
    const { order, fieldName } = this.sort;
    cpuSubSet.sort((a, b) => {
      if (order === 'dsc') {
        return a[fieldName] < b[fieldName] ? 1 : -1;
      } else {
        return a[fieldName] > b[fieldName] ? 1 : -1;
      }
    });
    // createURL
    let url = `${this.location.href.split('?')[0]}?`;
    let isFirstKey = true;
    Object.keys(newFilters).forEach((key) => {
      if (!newFilters[key].value) {
        return;
      }
      if (isFirstKey) {
        url += `${key}=${encodeURIComponent(newFilters[key].value)}`
        isFirstKey = false;
        return;
      }
      url += `&${key}=${encodeURIComponent(newFilters[key].value)}`
    });
    if (url[url.length -1] === '?') {
      url = url.replace('?','');
    }
    // set and rerender
    this.setState({
      cpuSubSet,
      checkboxes: newCheck,
      filters: newFilters,
      url,
      copied: false,
    });
  }

  handleChange = (e) => {
    let name = '';
    const newCheck = {
      ...this.state.checkboxes,
      [e.target.name]: e.target.checked
    };
    const newFilters = {
      ...this.state.filters
    };
    name += (newCheck['rtx4000'] && 'RTX 40,') || '';
    name += (newCheck['rtx3000'] && 'RTX 30,') || '';
    name += (newCheck['rtx2000'] && 'RTX 20,') || '';
    name += (newCheck['gtx16'] && 'GTX 16,') || '';
    name += (newCheck['gtx1000'] && 'GTX 10,') || '';
    name += (newCheck['radeonrx'] && 'Radeon RX,') || '';
    newFilters.name = {
      value: name,
      type: 'string'
    };
    this.setState({
      checkboxes: newCheck,
    });
    setTimeout(() => {
      this.createCpuList(newCheck,newFilters);
    }, 1);
  }

  sortTable = (fieldName) => {
    this.sort.fieldName = fieldName;
    this.sort.order = this.sort.fieldName === fieldName ? 
                  (this.sort.order === 'dsc' ? 'asc' : 'dsc')
                  : 'dsc';
    this.setState({order: this.sort.order});
    setTimeout(() => {
      this.createCpuList(this.state.checkboxes, this.state.filters);
    }, 1);
  }

  filterTable = (e, fieldName, type) => {
    const newFilters = {
      ...this.state.filters,
      [fieldName]: {
        value: e.target.value,
        type: type || 'number'
      }
    }
    this.createCpuList(this.state.checkboxes, newFilters);
  }

  // onCpuNameClick = (name) => {
  //   const keyword = name.replace(/Intel%20|AMD%20/, '');
  //   window.open(`https://www.amazon.co.jp/s/ref=as_li_ss_tl?k=${keyword}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_1&linkCode=ll2&tag=daigakuseitus-22&linkId=35d63770bb2f4224560575dae588479a&language=ja_JP`);
  // }

  onScaleClick = () => {
    if (this.state.scale === '縮小表示') {
      this.setState({
        scale: '拡大表示'
      });
    } else {
      this.setState({
        scale: '縮小表示'
      });
    }
  }

  resetFilter = () => {
    this.createCpuList({},{});
  }

  render() {
    const { checkboxes, storeLink } = this.props;
    return (
      <>
        <CopyToClipboard text={this.state.url} onCopy={() => this.setState({copied: true})}>
          <FlatButton className={"small"}>フィルタ結果をコピー</FlatButton>
        </CopyToClipboard>
        {this.state.copied ? <span style={{color: 'red'}}> Copied.</span> : null}
        <textarea id="copyTarget" style={{width: 'calc(95%)'}} readOnly >{this.state.url}</textarea><br/>
        { checkboxes && <>
          <div className="mobile-margin">定番絞り込み</div><CheckBoxes>
          {checkboxes.includes('geforce') && <>
            <input name="rtx4000" id="rtx4000" type="checkbox" checked={this.state.checkboxes["rtx4000"] || false} onChange={this.handleChange} />
            <label htmlFor="rtx4000">RTX4000</label>
            <input name="rtx3000" id="rtx3000" type="checkbox" checked={this.state.checkboxes["rtx3000"] || false} onChange={this.handleChange} />
            <label htmlFor="rtx3000">RTX3000</label>
            <input name="rtx2000" id="rtx2000" type="checkbox" checked={this.state.checkboxes["rtx2000"] || false} onChange={this.handleChange} />
            <label htmlFor="rtx2000">RTX2000</label>
            <input name="gtx16" id="gtx16" type="checkbox" checked={this.state.checkboxes["gtx16"] || false} onChange={this.handleChange} />
            <label htmlFor="gtx16">GTX16</label>
            <input name="gtx1000" id="gtx1000" type="checkbox" checked={this.state.checkboxes["gtx1000"] || false} onChange={this.handleChange} />
            <label htmlFor="gtx1000">GTX1000</label>
          </>}
          {checkboxes.includes('radeon') && <> 
            <input name="radeonrx" id="radeonrx" type="checkbox" checked={this.state.checkboxes["radeonrx"] || false} onChange={this.handleChange} />
            <label htmlFor="radeonrx" className="radeon">Radeon RX</label>
          </>}
        </CheckBoxes></>}
        <div className={"top-margin bottom-margin mobile-margin"}><em>{this.state.cpuSubSet.length}件</em>ヒット | GPU名クリックでAmazonへ | <a href="https://amzn.to/3FuK1Cf" target="_blank" rel="nofollow noopener">GPU売れ筋ランキング</a></div>
        {storeLink && <div className={"top-margin bottom-margin mobile-margin"}>パーツショップへ=&gt;<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000220&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000220&type=3&subid=0" ></img>| <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887072365&vc_url=https%3A%2F%2Fshop.tsukumo.co.jp%2Fparts%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887072365" height="1" width="0" border="0" alt=""/>TSUKUMO</a></div>}
        <FlatButton onClick={this.resetFilter} className={"small"}>フィルターをリセット</FlatButton>
        <FlatButton onClick={this.onScaleClick} css={{[mq('pc')] : {display: 'none'}}}className="left-margin small" >{this.state.scale}</FlatButton>
        <ContentWrapper>
          <TableWrapper className={this.state.scale === '拡大表示' && 'shrink'}>
            <thead>
              <tr>
                {this.fieldArray.map((field, i) => {
                  let order='';
                  if (this.sort.fieldName === field.id) {
                    order = this.sort.order;
                  }
                  return (
                    <th className='tablehead' key={field.id} id={`f${i}`} onClick={() => this.sortTable(field.id)} css={field.css}><div className={`inheader ${order}`}>{fieldNameMap[field.id]}</div></th>
                  )
                })}
              </tr>
              <tr>
                {this.fieldArray.map((field) => {
                  return (
                    <th className='tablehead' key={field.id}><input type="search" placeholder={field.placeholder} onChange={e => this.filterTable(e, field.id, field.filterType)} value={(this.state.filters[field.id] && this.state.filters[field.id].value) || ''}></input></th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
            {this.state.cpuSubSet.map(cpu => <TableRow cpu={cpu} fieldArray={this.fieldArray} key={cpu.name}/>)}
            </tbody>
          </TableWrapper>
        </ContentWrapper>
      </>
    )
  }
}